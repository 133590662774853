@charset "UTF-8";
.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.table-players .flag-icon {
  width: 23px;
  height: 23px;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 19px;
  height: 19px;
  line-height: 19px;
  border-radius: 50%;
  border: 1px solid var(--bs-gray-300);
}
.flag-icon:before {
  content: " ";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-abw {
  background-image: url(/images/flags/1x1/abw.png);
}

.flag-icon-afg {
  background-image: url(/images/flags/1x1/afg.png);
}

.flag-icon-ago {
  background-image: url(/images/flags/1x1/ago.png);
}

.flag-icon-aia {
  background-image: url(/images/flags/1x1/aia.png);
}

.flag-icon-ala {
  background-image: url(/images/flags/1x1/ala.png);
}

.flag-icon-alb {
  background-image: url(/images/flags/1x1/alb.png);
}

.flag-icon-and {
  background-image: url(/images/flags/1x1/and.png);
}

.flag-icon-are {
  background-image: url(/images/flags/1x1/are.png);
}

.flag-icon-arg {
  background-image: url(/images/flags/1x1/arg.png);
}

.flag-icon-arm {
  background-image: url(/images/flags/1x1/arm.png);
}

.flag-icon-asm {
  background-image: url(/images/flags/1x1/asm.png);
}

.flag-icon-ata {
  background-image: url(/images/flags/1x1/ata.png);
}

.flag-icon-atf {
  background-image: url(/images/flags/1x1/atf.png);
}

.flag-icon-atg {
  background-image: url(/images/flags/1x1/atg.png);
}

.flag-icon-aus {
  background-image: url(/images/flags/1x1/aus.png);
}

.flag-icon-aut {
  background-image: url(/images/flags/1x1/aut.png);
}

.flag-icon-aze {
  background-image: url(/images/flags/1x1/aze.png);
}

.flag-icon-bdi {
  background-image: url(/images/flags/1x1/bdi.png);
}

.flag-icon-bel {
  background-image: url(/images/flags/1x1/bel.png);
}

.flag-icon-ben {
  background-image: url(/images/flags/1x1/ben.png);
}

.flag-icon-bes {
  background-image: url(/images/flags/1x1/bes.png);
}

.flag-icon-bfa {
  background-image: url(/images/flags/1x1/bfa.png);
}

.flag-icon-bgd {
  background-image: url(/images/flags/1x1/bgd.png);
}

.flag-icon-bgr {
  background-image: url(/images/flags/1x1/bgr.png);
}

.flag-icon-bul {
  background-image: url(/images/flags/1x1/bul.png);
}

.flag-icon-bhr {
  background-image: url(/images/flags/1x1/bhr.png);
}

.flag-icon-bhs {
  background-image: url(/images/flags/1x1/bhs.png);
}

.flag-icon-bih {
  background-image: url(/images/flags/1x1/bih.png);
}

.flag-icon-blm {
  background-image: url(/images/flags/1x1/blm.png);
}

.flag-icon-blr {
  background-image: url(/images/flags/1x1/blr.png);
}

.flag-icon-blz {
  background-image: url(/images/flags/1x1/blz.png);
}

.flag-icon-bmu {
  background-image: url(/images/flags/1x1/bmu.png);
}

.flag-icon-bol {
  background-image: url(/images/flags/1x1/bol.png);
}

.flag-icon-bra {
  background-image: url(/images/flags/1x1/bra.png);
}

.flag-icon-brb {
  background-image: url(/images/flags/1x1/brb.png);
}

.flag-icon-brn {
  background-image: url(/images/flags/1x1/brn.png);
}

.flag-icon-btn {
  background-image: url(/images/flags/1x1/btn.png);
}

.flag-icon-bvt {
  background-image: url(/images/flags/1x1/bvt.png);
}

.flag-icon-bwa {
  background-image: url(/images/flags/1x1/bwa.png);
}

.flag-icon-caf {
  background-image: url(/images/flags/1x1/caf.png);
}

.flag-icon-can {
  background-image: url(/images/flags/1x1/can.png);
}

.flag-icon-cat {
  background-image: url(/images/flags/1x1/cat.png);
}

.flag-icon-cck {
  background-image: url(/images/flags/1x1/cck.png);
}

.flag-icon-sui {
  background-image: url(/images/flags/1x1/sui.png);
}

.flag-icon-chl {
  background-image: url(/images/flags/1x1/chl.png);
}

.flag-icon-chi {
  background-image: url(/images/flags/1x1/chi.png);
}

.flag-icon-chn {
  background-image: url(/images/flags/1x1/chn.png);
}

.flag-icon-civ {
  background-image: url(/images/flags/1x1/civ.png);
}

.flag-icon-cmr {
  background-image: url(/images/flags/1x1/cmr.png);
}

.flag-icon-cod {
  background-image: url(/images/flags/1x1/cod.png);
}

.flag-icon-cog {
  background-image: url(/images/flags/1x1/cog.png);
}

.flag-icon-cok {
  background-image: url(/images/flags/1x1/cok.png);
}

.flag-icon-col {
  background-image: url(/images/flags/1x1/col.png);
}

.flag-icon-com {
  background-image: url(/images/flags/1x1/com.png);
}

.flag-icon-cpv {
  background-image: url(/images/flags/1x1/cpv.png);
}

.flag-icon-cri {
  background-image: url(/images/flags/1x1/cri.png);
}

.flag-icon-cub {
  background-image: url(/images/flags/1x1/cub.png);
}

.flag-icon-cuw {
  background-image: url(/images/flags/1x1/cuw.png);
}

.flag-icon-cxr {
  background-image: url(/images/flags/1x1/cxr.png);
}

.flag-icon-cym {
  background-image: url(/images/flags/1x1/cym.png);
}

.flag-icon-cyp {
  background-image: url(/images/flags/1x1/cyp.png);
}

.flag-icon-cze {
  background-image: url(/images/flags/1x1/cze.png);
}

.flag-icon-ger {
  background-image: url(/images/flags/1x1/ger.png);
}

.flag-icon-dji {
  background-image: url(/images/flags/1x1/dji.png);
}

.flag-icon-dma {
  background-image: url(/images/flags/1x1/dma.png);
}

.flag-icon-den {
  background-image: url(/images/flags/1x1/den.png);
}

.flag-icon-dom {
  background-image: url(/images/flags/1x1/dom.png);
}

.flag-icon-dza {
  background-image: url(/images/flags/1x1/dza.png);
}

.flag-icon-ecu {
  background-image: url(/images/flags/1x1/ecu.png);
}

.flag-icon-egy {
  background-image: url(/images/flags/1x1/egy.png);
}

.flag-icon-eng {
  background-image: url(/images/flags/1x1/eng.png);
}

.flag-icon-eri {
  background-image: url(/images/flags/1x1/eri.png);
}

.flag-icon-esh {
  background-image: url(/images/flags/1x1/esh.png);
}

.flag-icon-esp {
  background-image: url(/images/flags/1x1/esp.png);
}

.flag-icon-est {
  background-image: url(/images/flags/1x1/est.png);
}

.flag-icon-eth {
  background-image: url(/images/flags/1x1/eth.png);
}

.flag-icon-eun {
  background-image: url(/images/flags/1x1/eun.png);
}

.flag-icon-fin {
  background-image: url(/images/flags/1x1/fin.png);
}

.flag-icon-fji {
  background-image: url(/images/flags/1x1/fji.png);
}

.flag-icon-flk {
  background-image: url(/images/flags/1x1/flk.png);
}

.flag-icon-fra {
  background-image: url(/images/flags/1x1/fra.png);
}

.flag-icon-fro {
  background-image: url(/images/flags/1x1/fro.png);
}

.flag-icon-fsm {
  background-image: url(/images/flags/1x1/fsm.png);
}

.flag-icon-gab {
  background-image: url(/images/flags/1x1/gab.png);
}

.flag-icon-gbr {
  background-image: url(/images/flags/1x1/gbr.png);
}

.flag-icon-geo {
  background-image: url(/images/flags/1x1/geo.png);
}

.flag-icon-ggy {
  background-image: url(/images/flags/1x1/ggy.png);
}

.flag-icon-gha {
  background-image: url(/images/flags/1x1/gha.png);
}

.flag-icon-gib {
  background-image: url(/images/flags/1x1/gib.png);
}

.flag-icon-gin {
  background-image: url(/images/flags/1x1/gin.png);
}

.flag-icon-glp {
  background-image: url(/images/flags/1x1/glp.png);
}

.flag-icon-gmb {
  background-image: url(/images/flags/1x1/gmb.png);
}

.flag-icon-gnb {
  background-image: url(/images/flags/1x1/gnb.png);
}

.flag-icon-gnq {
  background-image: url(/images/flags/1x1/gnq.png);
}

.flag-icon-grc {
  background-image: url(/images/flags/1x1/grc.png);
}

.flag-icon-gre {
  background-image: url(/images/flags/1x1/gre.png);
}

.flag-icon-grd {
  background-image: url(/images/flags/1x1/grd.png);
}

.flag-icon-grl {
  background-image: url(/images/flags/1x1/grl.png);
}

.flag-icon-gtm {
  background-image: url(/images/flags/1x1/gtm.png);
}

.flag-icon-guf {
  background-image: url(/images/flags/1x1/guf.png);
}

.flag-icon-gum {
  background-image: url(/images/flags/1x1/gum.png);
}

.flag-icon-guy {
  background-image: url(/images/flags/1x1/guy.png);
}

.flag-icon-hkg {
  background-image: url(/images/flags/1x1/hkg.png);
}

.flag-icon-hmd {
  background-image: url(/images/flags/1x1/hmd.png);
}

.flag-icon-hnd {
  background-image: url(/images/flags/1x1/hnd.png);
}

.flag-icon-hrv {
  background-image: url(/images/flags/1x1/hrv.png);
}

.flag-icon-cro {
  background-image: url(/images/flags/1x1/cro.png);
}

.flag-icon-hti {
  background-image: url(/images/flags/1x1/hti.png);
}

.flag-icon-hun {
  background-image: url(/images/flags/1x1/hun.png);
}

.flag-icon-idn {
  background-image: url(/images/flags/1x1/idn.png);
}

.flag-icon-imn {
  background-image: url(/images/flags/1x1/imn.png);
}

.flag-icon-ind {
  background-image: url(/images/flags/1x1/ind.png);
}

.flag-icon-iot {
  background-image: url(/images/flags/1x1/iot.png);
}

.flag-icon-irl {
  background-image: url(/images/flags/1x1/irl.png);
}

.flag-icon-irn {
  background-image: url(/images/flags/1x1/irn.png);
}

.flag-icon-irq {
  background-image: url(/images/flags/1x1/irq.png);
}

.flag-icon-isl {
  background-image: url(/images/flags/1x1/isl.png);
}

.flag-icon-isr {
  background-image: url(/images/flags/1x1/isr.png);
}

.flag-icon-ita {
  background-image: url(/images/flags/1x1/ita.png);
}

.flag-icon-jam {
  background-image: url(/images/flags/1x1/jam.png);
}

.flag-icon-jey {
  background-image: url(/images/flags/1x1/jey.png);
}

.flag-icon-jor {
  background-image: url(/images/flags/1x1/jor.png);
}

.flag-icon-jpn {
  background-image: url(/images/flags/1x1/jpn.png);
}

.flag-icon-kaz {
  background-image: url(/images/flags/1x1/kaz.png);
}

.flag-icon-ken {
  background-image: url(/images/flags/1x1/ken.png);
}

.flag-icon-kgz {
  background-image: url(/images/flags/1x1/kgz.png);
}

.flag-icon-khm {
  background-image: url(/images/flags/1x1/khm.png);
}

.flag-icon-kir {
  background-image: url(/images/flags/1x1/kir.png);
}

.flag-icon-kna {
  background-image: url(/images/flags/1x1/kna.png);
}

.flag-icon-kor {
  background-image: url(/images/flags/1x1/kor.png);
}

.flag-icon-kos {
  background-image: url(/images/flags/1x1/kos.png);
}

.flag-icon-kwt {
  background-image: url(/images/flags/1x1/kwt.png);
}

.flag-icon-lao {
  background-image: url(/images/flags/1x1/lao.png);
}

.flag-icon-lbn {
  background-image: url(/images/flags/1x1/lbn.png);
}

.flag-icon-lbr {
  background-image: url(/images/flags/1x1/lbr.png);
}

.flag-icon-lby {
  background-image: url(/images/flags/1x1/lby.png);
}

.flag-icon-lca {
  background-image: url(/images/flags/1x1/lca.png);
}

.flag-icon-lie {
  background-image: url(/images/flags/1x1/lie.png);
}

.flag-icon-lka {
  background-image: url(/images/flags/1x1/lka.png);
}

.flag-icon-lso {
  background-image: url(/images/flags/1x1/lso.png);
}

.flag-icon-ltu {
  background-image: url(/images/flags/1x1/ltu.png);
}

.flag-icon-lux {
  background-image: url(/images/flags/1x1/lux.png);
}

.flag-icon-lva {
  background-image: url(/images/flags/1x1/lva.png);
}

.flag-icon-mac {
  background-image: url(/images/flags/1x1/mac.png);
}

.flag-icon-maf {
  background-image: url(/images/flags/1x1/maf.png);
}

.flag-icon-mar {
  background-image: url(/images/flags/1x1/mar.png);
}

.flag-icon-mco {
  background-image: url(/images/flags/1x1/mco.png);
}

.flag-icon-mon {
  background-image: url(/images/flags/1x1/mon.png);
}

.flag-icon-mda {
  background-image: url(/images/flags/1x1/mda.png);
}

.flag-icon-mdg {
  background-image: url(/images/flags/1x1/mdg.png);
}

.flag-icon-mdv {
  background-image: url(/images/flags/1x1/mdv.png);
}

.flag-icon-mex {
  background-image: url(/images/flags/1x1/mex.png);
}

.flag-icon-mhl {
  background-image: url(/images/flags/1x1/mhl.png);
}

.flag-icon-mkd {
  background-image: url(/images/flags/1x1/mkd.png);
}

.flag-icon-mli {
  background-image: url(/images/flags/1x1/mli.png);
}

.flag-icon-mlt {
  background-image: url(/images/flags/1x1/mlt.png);
}

.flag-icon-mmr {
  background-image: url(/images/flags/1x1/mmr.png);
}

.flag-icon-mne {
  background-image: url(/images/flags/1x1/mne.png);
}

.flag-icon-mng {
  background-image: url(/images/flags/1x1/mng.png);
}

.flag-icon-mnp {
  background-image: url(/images/flags/1x1/mnp.png);
}

.flag-icon-moz {
  background-image: url(/images/flags/1x1/moz.png);
}

.flag-icon-mrt {
  background-image: url(/images/flags/1x1/mrt.png);
}

.flag-icon-msr {
  background-image: url(/images/flags/1x1/msr.png);
}

.flag-icon-mtq {
  background-image: url(/images/flags/1x1/mtq.png);
}

.flag-icon-mus {
  background-image: url(/images/flags/1x1/mus.png);
}

.flag-icon-mwi {
  background-image: url(/images/flags/1x1/mwi.png);
}

.flag-icon-mas {
  background-image: url(/images/flags/1x1/mas.png);
}

.flag-icon-myt {
  background-image: url(/images/flags/1x1/myt.png);
}

.flag-icon-nam {
  background-image: url(/images/flags/1x1/nam.png);
}

.flag-icon-ncl {
  background-image: url(/images/flags/1x1/ncl.png);
}

.flag-icon-ner {
  background-image: url(/images/flags/1x1/ner.png);
}

.flag-icon-nfk {
  background-image: url(/images/flags/1x1/nfk.png);
}

.flag-icon-ngr {
  background-image: url(/images/flags/1x1/ngr.png);
}

.flag-icon-nic {
  background-image: url(/images/flags/1x1/nic.png);
}

.flag-icon-nir {
  background-image: url(/images/flags/1x1/nir.png);
}

.flag-icon-niu {
  background-image: url(/images/flags/1x1/niu.png);
}

.flag-icon-ned {
  background-image: url(/images/flags/1x1/ned.png);
}

.flag-icon-nor {
  background-image: url(/images/flags/1x1/nor.png);
}

.flag-icon-npl {
  background-image: url(/images/flags/1x1/npl.png);
}

.flag-icon-nru {
  background-image: url(/images/flags/1x1/nru.png);
}

.flag-icon-nzl {
  background-image: url(/images/flags/1x1/nzl.png);
}

.flag-icon-omn {
  background-image: url(/images/flags/1x1/omn.png);
}

.flag-icon-pak {
  background-image: url(/images/flags/1x1/pak.png);
}

.flag-icon-par {
  background-image: url(/images/flags/1x1/par.png);
}

.flag-icon-pan {
  background-image: url(/images/flags/1x1/pan.png);
}

.flag-icon-pcn {
  background-image: url(/images/flags/1x1/pcn.png);
}

.flag-icon-per {
  background-image: url(/images/flags/1x1/per.png);
}

.flag-icon-phl {
  background-image: url(/images/flags/1x1/phl.png);
}

.flag-icon-phi {
  background-image: url(/images/flags/1x1/phi.png);
}

.flag-icon-plw {
  background-image: url(/images/flags/1x1/plw.png);
}

.flag-icon-png {
  background-image: url(/images/flags/1x1/png.png);
}

.flag-icon-pol {
  background-image: url(/images/flags/1x1/pol.png);
}

.flag-icon-pri {
  background-image: url(/images/flags/1x1/pri.png);
}

.flag-icon-prk {
  background-image: url(/images/flags/1x1/prk.png);
}

.flag-icon-por {
  background-image: url(/images/flags/1x1/por.png);
}

.flag-icon-pry {
  background-image: url(/images/flags/1x1/pry.png);
}

.flag-icon-pra {
  background-image: url(/images/flags/1x1/pra.png);
}

.flag-icon-pse {
  background-image: url(/images/flags/1x1/pse.png);
}

.flag-icon-pyf {
  background-image: url(/images/flags/1x1/pyf.png);
}

.flag-icon-qat {
  background-image: url(/images/flags/1x1/qat.png);
}

.flag-icon-reu {
  background-image: url(/images/flags/1x1/reu.png);
}

.flag-icon-rou {
  background-image: url(/images/flags/1x1/rou.png);
}

.flag-icon-rus {
  background-image: url(/images/flags/1x1/rus.png);
}

.flag-icon-rwa {
  background-image: url(/images/flags/1x1/rwa.png);
}

.flag-icon-sau {
  background-image: url(/images/flags/1x1/sau.png);
}

.flag-icon-sco {
  background-image: url(/images/flags/1x1/sco.png);
}

.flag-icon-sdn {
  background-image: url(/images/flags/1x1/sdn.png);
}

.flag-icon-sen {
  background-image: url(/images/flags/1x1/sen.png);
}

.flag-icon-sgp {
  background-image: url(/images/flags/1x1/sgp.png);
}

.flag-icon-sgs {
  background-image: url(/images/flags/1x1/sgs.png);
}

.flag-icon-shn {
  background-image: url(/images/flags/1x1/shn.png);
}

.flag-icon-sjm {
  background-image: url(/images/flags/1x1/sjm.png);
}

.flag-icon-slb {
  background-image: url(/images/flags/1x1/slb.png);
}

.flag-icon-sle {
  background-image: url(/images/flags/1x1/sle.png);
}

.flag-icon-slv {
  background-image: url(/images/flags/1x1/slv.png);
}

.flag-icon-esa {
  background-image: url(/images/flags/1x1/esa.png);
}

.flag-icon-smr {
  background-image: url(/images/flags/1x1/smr.png);
}

.flag-icon-som {
  background-image: url(/images/flags/1x1/som.png);
}

.flag-icon-spm {
  background-image: url(/images/flags/1x1/spm.png);
}

.flag-icon-srb {
  background-image: url(/images/flags/1x1/srb.png);
}

.flag-icon-ssd {
  background-image: url(/images/flags/1x1/ssd.png);
}

.flag-icon-stp {
  background-image: url(/images/flags/1x1/stp.png);
}

.flag-icon-sun {
  background-image: url(/images/flags/1x1/sun.png);
}

.flag-icon-sur {
  background-image: url(/images/flags/1x1/sur.png);
}

.flag-icon-svk {
  background-image: url(/images/flags/1x1/svk.png);
}

.flag-icon-slo {
  background-image: url(/images/flags/1x1/slo.png);
}

.flag-icon-swe {
  background-image: url(/images/flags/1x1/swe.png);
}

.flag-icon-swz {
  background-image: url(/images/flags/1x1/swz.png);
}

.flag-icon-sxm {
  background-image: url(/images/flags/1x1/sxm.png);
}

.flag-icon-syc {
  background-image: url(/images/flags/1x1/syc.png);
}

.flag-icon-syr {
  background-image: url(/images/flags/1x1/syr.png);
}

.flag-icon-tca {
  background-image: url(/images/flags/1x1/tca.png);
}

.flag-icon-tcd {
  background-image: url(/images/flags/1x1/tcd.png);
}

.flag-icon-tgo {
  background-image: url(/images/flags/1x1/tgo.png);
}

.flag-icon-tha {
  background-image: url(/images/flags/1x1/tha.png);
}

.flag-icon-tjk {
  background-image: url(/images/flags/1x1/tjk.png);
}

.flag-icon-tkl {
  background-image: url(/images/flags/1x1/tkl.png);
}

.flag-icon-tkm {
  background-image: url(/images/flags/1x1/tkm.png);
}

.flag-icon-tls {
  background-image: url(/images/flags/1x1/tls.png);
}

.flag-icon-ton {
  background-image: url(/images/flags/1x1/ton.png);
}

.flag-icon-tto {
  background-image: url(/images/flags/1x1/tto.png);
}

.flag-icon-tun {
  background-image: url(/images/flags/1x1/tun.png);
}

.flag-icon-tur {
  background-image: url(/images/flags/1x1/tur.png);
}

.flag-icon-tuv {
  background-image: url(/images/flags/1x1/tuv.png);
}

.flag-icon-twn {
  background-image: url(/images/flags/1x1/twn.png);
}

.flag-icon-tpe {
  background-image: url(/images/flags/1x1/tpe.png);
}

.flag-icon-tza {
  background-image: url(/images/flags/1x1/tza.png);
}

.flag-icon-uga {
  background-image: url(/images/flags/1x1/uga.png);
}

.flag-icon-ukr {
  background-image: url(/images/flags/1x1/ukr.png);
}

.flag-icon-umi {
  background-image: url(/images/flags/1x1/umi.png);
}

.flag-icon-uru {
  background-image: url(/images/flags/1x1/uru.png);
}

.flag-icon-ury {
  background-image: url(/images/flags/1x1/ury.png);
}

.flag-icon-usa {
  background-image: url(/images/flags/1x1/usa.png);
}

.flag-icon-uzb {
  background-image: url(/images/flags/1x1/uzb.png);
}

.flag-icon-vat {
  background-image: url(/images/flags/1x1/vat.png);
}

.flag-icon-vct {
  background-image: url(/images/flags/1x1/vct.png);
}

.flag-icon-ven {
  background-image: url(/images/flags/1x1/ven.png);
}

.flag-icon-vgb {
  background-image: url(/images/flags/1x1/vgb.png);
}

.flag-icon-isv {
  background-image: url(/images/flags/1x1/isv.png);
}

.flag-icon-vnm {
  background-image: url(/images/flags/1x1/vnm.png);
}

.flag-icon-vut {
  background-image: url(/images/flags/1x1/vut.png);
}

.flag-icon-wal {
  background-image: url(/images/flags/1x1/wal.png);
}

.flag-icon-wlf {
  background-image: url(/images/flags/1x1/wlf.png);
}

.flag-icon-wsm {
  background-image: url(/images/flags/1x1/wsm.png);
}

.flag-icon-yem {
  background-image: url(/images/flags/1x1/yem.png);
}

.flag-icon-rsa {
  background-image: url(/images/flags/1x1/rsa.png);
}

.flag-icon-zmb {
  background-image: url(/images/flags/1x1/zmb.png);
}

.flag-icon-zwe {
  background-image: url(/images/flags/1x1/zwe.png);
}