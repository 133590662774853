.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.table-players {
  .flag-icon {
    width: 23px;
    height: 23px;
  }
}

.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  // width: 1.333333 * 1em;
  width: 19px;
  height: 19px;
  line-height: 19px;
  border-radius: 50%;
  border: 1px solid var(--bs-gray-300);
  &:before {
    content: "\00a0";
  }
  &.flag-icon-squared {
    width: 1em;
  }
}

@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}#{$flag-icon-square-path}/#{$country}.png);
  }
}
